import Page from './Page';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Technology = () => {
  return <Page section="technology">
    <Row>
      <Col>
        <div className="p-5 m-5 text-center">
          <h1 className="display-5 fw-bold">Its almost then end of 2024!</h1>
          <p className="lead mb-4">Start with us before September 1st and lock in to our Launch Pricing to <br />save thousands of dollars in your company's journey to the moon!</p>
          <p className="mt-5">When subscribing you will also QUALIFY for these Massive Savings now for when you need them most!</p>
          <p className="lead mb-4">LOCK IN LAUNCH PRICING FOR YOUR SUBSCRIPTION:</p>
          <p>
            Support (20h) @ <em><strike>$1,997</strike> <strong>$1,250</strong></em><br />
            Growth (60h) @ <em><strike>$4,957</strike> <strong>$3,750</strong></em><br />
            Innovation (200h) @ <em><strike>$14,997</strike> <strong>$12,500</strong></em>
          </p>
            
          <div className="col-lg-8 mx-auto my-5">
            <div style={{position: "relative", paddingBottom: "63.77473363774734%", height: 0}}>
              <iframe src="https://www.loom.com/embed/0f7f678c2afb45f98cbc57c13cbfafda" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}} title="End-Of-Summer-Pitch"></iframe>
            </div>
          </div>
          <p className="mt-5"><a href="mailto:rick@raddstudio.com?subject=End of Summer 2024 Exclusive Offer? Sign me up!">Connect with us today</a> or <a href="https://buy.stripe.com/dR68zYevc8RVggg3ce">get started now</a> for <strong>ONLY $250</strong><br /> 
          You get 4x the monthly success meetings!<br/>
          A value of $900 per month! SAVE $650 every month!<br />
          Our weekly service for our monthly price!</p>
          <p className="lead mb-4">If you dont see the value in working with us, you cancel at any time! No obligation!<br /> <strong>Thats our <em>GUARANTEE</em></strong></p>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="px-4 py-5 my-5 text-center">
          <h1 className="display-5 fw-bold">Technology</h1>
          <div className="col-lg-6 mx-auto my-5">
            <p className="lead mb-4">Since the 90s, we have been getting creative with web and software design and development. At first it was just for fun and out of curiosity, but we have carried that same energy forward an entire career later. Process, architecture, design, and development has been in the spotlight on the daily. We wouldnt have it anyother way.</p>
            <p className="mt-5">Some tools that we love:</p>
            <span><FontAwesomeIcon icon={['fab',"js"]} size="2xl" className="m-5" /></span>
            <span><FontAwesomeIcon icon={['fab',"node-js"]} size="2xl" className="m-5" /></span>
            <span><img alt="" id="rubyIcon" className="m-5 fa-2xl" src="/images/ruby.png"/></span>
            <span><img alt="" className="mx-3 my-5 fa-2xl" src="/images/rails.png" /></span>
            <span><FontAwesomeIcon icon={['fab',"react"]} size="2xl" className="m-5" /></span>
            <span><FontAwesomeIcon icon={['fab',"figma"]} size="2xl" className="m-5" /></span>
            <span><FontAwesomeIcon icon={['fab',"atlassian"]} size="2xl" className="m-5" /></span>
            <span><FontAwesomeIcon icon={['fab',"github"]} size="2xl" className="m-5" /></span>
            <span><FontAwesomeIcon icon={['fab',"digital-ocean"]} size="2xl" className="m-5" /></span>
            <span><FontAwesomeIcon icon={['fab',"cloudflare"]} size="2xl" className="m-5" /></span>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <a href="#connect" className="btn btn-primary btn-lg px-4 gap-3">Connect</a>
              <a target="substack" href="https://raddstudio.substack.com/" className="btn btn-outline-secondary btn-lg px-4">Learn more</a>
            </div>
          </div>
          <div className="overflow-hidden" style={{maxHeight: '30vh'}}>
            <div className="px-5">
              <img alt="" src="/images/evedo_desk.jpg" className="img-fluid border rounded-3 shadow-lg mb-4" width="700" height="500" loading="lazy" />
            </div>
          </div>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="text-center">
          <div className="col">
            <p>Some companies that we put our blood, sweat, and tears into over the years:</p>
          </div>
        </div>
      </Col>
    </Row>
    <div className="container">
      <Row className="text-start justify-content-center my-5 ms-1">
        <Col sm="6" lg="4" xxl="3">
          <Card className="my-2">
            <Card.Body>
              <Card.Title>TNS-Smart.net</Card.Title>
              <Card.Text>
                 A white label ATM switch connecting vendors to the major 6 Banks of Canada, and specializing on Casino services.
              </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <Badge pill bg="light" className="text-black">Fin-Tech</Badge>
                <Badge pill bg="light" className="text-black">Javascript</Badge>
                <Badge pill bg="light" className="text-black">ASP.net</Badge>
                <Badge pill bg="light" className="text-black">C#.net</Badge>
                <Badge pill bg="light" className="text-black">C++</Badge>
                <Badge pill bg="light" className="text-black">MSSQL</Badge>
              </ListGroup.Item>
            </ListGroup>
            <Card.Body>
              <Card.Link href="https://www.crunchbase.com/organization/tns-smart-network" target="_blank">Crunchbase</Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="4" xxl="3">
          <Card className="my-2">
            <Card.Body>
              <Card.Title>Threshold-FTI</Card.Title>
              <Card.Text>
                 An ATM switch service that provides services the major 6 Banks of Canada.
              </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <Badge pill bg="light" className="text-black">Fin-Tech</Badge>
                <Badge pill bg="light" className="text-black">Javascript</Badge>
                <Badge pill bg="light" className="text-black">ASP.net</Badge>
                <Badge pill bg="light" className="text-black">C#.net</Badge>
                <Badge pill bg="light" className="text-black">MSSQL</Badge>
              </ListGroup.Item>
            </ListGroup>
            <Card.Body>
              <Card.Link href="https://www.crunchbase.com/organization/threshold-financial-technologies-inc" target="_blank">Crunchbase</Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="4" xxl="3">
          <Card className="my-2">
            <Card.Body>
              <Card.Title>Patientway</Card.Title>
              <Card.Text>
                A self-service suite of products that helps build a better experience for your hospital visits.
              </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <Badge pill bg="light" className="text-black">Healthcare Tech</Badge>
                <Badge pill bg="light" className="text-black">Javascript</Badge>
                <Badge pill bg="light" className="text-black">SVG</Badge>
                <Badge pill bg="light" className="text-black">Ruby on Rails</Badge>
                <Badge pill bg="light" className="text-black">MongoDB</Badge>
                <Badge pill bg="light" className="text-black">PostgreSQL</Badge>
              </ListGroup.Item>
            </ListGroup>
            <Card.Body>
              <Card.Link href="https://www.crunchbase.com/organization/patientway" target="_blank">Crunchbase</Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="4" xxl="3">
          <Card className="my-2">
            <Card.Body>
              <Card.Title>One Move Technologies</Card.Title>
              <Card.Text>
                 An e-conveyance system to manage & assemble legal documents needed in the sale of real estate 
              </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <Badge pill bg="light" className="text-black">Real Estate Tech</Badge>
                <Badge pill bg="light" className="text-black">Javascript</Badge>
                <Badge pill bg="light" className="text-black">ASP.net</Badge>
                <Badge pill bg="light" className="text-black">C#.net</Badge>
                <Badge pill bg="light" className="text-black">Ruby on Rails</Badge>
                <Badge pill bg="light" className="text-black">PostgreSQL</Badge>
              </ListGroup.Item>
            </ListGroup>
            <Card.Body>
              <Card.Link href="https://www.crunchbase.com/organization/onemove-technologies" target="_blank">Crunchbase</Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="4" xxl="3">
          <Card className="my-2">
            <Card.Body>
              <Card.Title>Nomo-Fomo</Card.Title>
              <Card.Text>
                A social travel platform that allows you to plan your trips with friends
              </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <Badge pill bg="light" className="text-black">Travel Tech</Badge>
                <Badge pill bg="light" className="text-black">Javascript</Badge>
                <Badge pill bg="light" className="text-black">Ruby on Rails</Badge>
                <Badge pill bg="light" className="text-black">PostgreSQL</Badge>
              </ListGroup.Item>
            </ListGroup>
            <Card.Body>
              <Card.Link href="https://www.crunchbase.com/organization/nomo-fomo" target="_blank">Crunchbase</Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="4" xxl="3">
          <Card className="my-2">
            <Card.Body>
              <Card.Title>Plentix.co</Card.Title>
              <Card.Text>
                A the global event platform For social explorers. Connect with like-minded people by discovering, booking, and sharing events.
              </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <Badge pill bg="light" className="text-black">Event Tech</Badge>
                <Badge pill bg="light" className="text-black">Javascript</Badge>
                <Badge pill bg="light" className="text-black">PHP</Badge>
                <Badge pill bg="light" className="text-black">Node.js</Badge>
                <Badge pill bg="light" className="text-black">Express.js</Badge>
                <Badge pill bg="light" className="text-black">React.js</Badge>
                <Badge pill bg="light" className="text-black">Ruby on Rails</Badge>
                <Badge pill bg="light" className="text-black">PostgreSQL</Badge>
                <Badge pill bg="light" className="text-black">MongoDB</Badge>
                <Badge pill bg="light" className="text-black">Web3</Badge>
              </ListGroup.Item>
            </ListGroup>
            <Card.Body>
              <Card.Link href="https://plentix.co">Plentix.co</Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="4" xxl="3">
          <Card className="my-2">
            <Card.Body>
              <Card.Title>Gigix.app</Card.Title>
              <Card.Text>
                A place where entertainers can benefit of getting more gigs by being discoverable. Entertainers can be any type of performers - speakers, dj's, singers, dancers, etc.
              </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <Badge pill bg="light" className="text-black">Event Tech</Badge>
                <Badge pill bg="light" className="text-black">Javascript</Badge>
                <Badge pill bg="light" className="text-black">React.js</Badge>
                <Badge pill bg="light" className="text-black">Ruby on Rails</Badge>
                <Badge pill bg="light" className="text-black">PostgreSQL</Badge>
                <Badge pill bg="light" className="text-black">Web3</Badge>
              </ListGroup.Item>
            </ListGroup>
            <Card.Body>
              <Card.Link href="https://gigix.app">Gigix.app</Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="4" xxl="3">
          <Card className="my-2">
            <Card.Body>
              <Card.Title>Fragmint</Card.Title>
              <Card.Text>
                DAO Tools to drive your community through your NFT programs
              </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <Badge pill bg="light" className="text-black">Entertainment</Badge>
                <Badge pill bg="light" className="text-black">Javascript</Badge>
                <Badge pill bg="light" className="text-black">Node.js</Badge>
                <Badge pill bg="light" className="text-black">Express.js</Badge>
                <Badge pill bg="light" className="text-black">React.js</Badge>
                <Badge pill bg="light" className="text-black">MongoDB</Badge>
                <Badge pill bg="light" className="text-black">Web3</Badge>
              </ListGroup.Item>
            </ListGroup>
            <Card.Body>
              <Card.Link href="https://fragmint.com">Fragmint.com</Card.Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-center">
            <div className="col mb-5">
              <p>..and many more contributed to, with love❣️</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </Page>
}

export default Technology;
