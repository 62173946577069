import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Layout from './pages/Layout';
import Home from './pages/Home';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Affiliates from './pages/Affiliates';
import Survey from './pages/Survey';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <></>,
    children: [
	  {
	    path: "/",
	    element: <Home />,
	  },
	  {
	    path: "/terms",
	    element: <Terms />,
	  },
	  {
	    path: "/privacy",
	    element: <Privacy />,
	  },
	  {
	    path: "/affiliates",
	    element: <Affiliates />,
	  },
	  {
	    path: "/survey",
	    element: <Survey />,
	  },
    ],
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
